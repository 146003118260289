import React from "react";
import { graphql } from "gatsby";
import Page from "../../components/page";

type Props = {
  data: Queries.ContentModulesPageQueryDataQuery;
};

const ContentModulesPage: React.FunctionComponent<Props> = ({ data }) => {
  return (
    <Page
      {...data.datoCmsWebPage}
      container={(data.datoCmsWebPage.container as any) || "fluid"}
    />
  );
};

export const query = graphql`
  query ContentModulesPageQueryData($id: String!, $locale: String!) {
    datoCmsWebPage(id: { eq: $id }, locale: { eq: $locale }) {
      ...WebPageFragment
    }
  }
`;

export default ContentModulesPage;
